<template>
  <svg
    class="chart chart__loader skeleton-loader"
    :height="height"
    :width="width"
  >
    <g
      v-for="(input, index) in compInputs"
      :key="`input-${index}`"
    >
      <text
        x="0"
        :y="input.y + 20"
      >{{ input.name }}</text>
      <text
        fill="#a2a8c1"
        font-weight="500"
        x="0"
        :y="input.y + 40"
      >
        {{ input.compValue }}
      </text>
      <rect
        :fill="input.color"
        :height="input.height"
        :width="nodeSize"
        x="0"
        :y="input.y + 50"
      />
      <path
        :d="createIncomeCurve(index, input)"
        fill="none"
        :stroke="input.color"
        stroke-opacity="0.25"
        :stroke-width="input.height"
      />
    </g>
    <g
      v-for="(output, index) in compOutputs"
      :key="index"
    >
      <text
        text-anchor="end"
        :x="width"
        :y="output.y + 20"
      >
        {{ output.name }}
      </text>
      <text
        fill="#a2a8c1"
        font-weight="500"
        text-anchor="end"
        :x="width"
        :y="output.y + 40"
      >
        {{ output.compValue }}
      </text>
      <rect
        :fill="output.color"
        :height="output.height"
        :width="nodeSize"
        :x="width - 150"
        :y="output.y + 50"
      />
      <path
        :d="createOutcomeCurve(index, output)"
        fill="none"
        :stroke="output.color"
        stroke-opacity="0.25"
        :stroke-width="output.height"
      />
    </g>
    <WalletLoader :x="(width - 36) / 2" />
    <text
      text-anchor="middle"
      :x="width / 2"
      :y="48"
    >-------</text>
    <text
      fill="#a2a8c1"
      font-weight="500"
      text-anchor="middle"
      :x="width / 2"
      :y="68"
    >
      --------
    </text>
    <rect
      fill="#f0f2f9"
      :height="nodeSize"
      :width="nodeSize"
      :x="(width - nodeSize) / 2"
      :y="80"
    />
  </svg>
</template>

<script>
import WalletLoader from "@/assets/icons/wallet-loading.svg?inline";
export default {
  components: {
    WalletLoader
  },
  props: {
    width: {
      type: Number,
      default: 600
    },
    loading: {
      type: Boolean,
      default: true
    },
    nodeSize: {
      type: Number,
      default: 150
    }
  },
  data() {
    return {
      colors: [],
      inputs: [
        { name: "--------", value: 1 },
        { name: "--------", value: 2.5 },
        { name: "--------", value: 5 },
        { name: "--------", value: 0.25 }
      ],
      outputs: [
        { name: "--------", value: 2 },
        { name: "--------", value: 1 },
        { name: "--------", value: 0.25 },
        { name: "--------", value: 0.5 }
      ]
    };
  },
  computed: {
    height() {
      let inc = this.inputs.length * 56 + this.nodeSize;
      let out = this.outputs.length * 56 + this.nodeSize;
      return Math.max(inc, out);
    },
    incomeY() {
      return this.inputs.map(input => {
        return (input.value / this.inputSum).toFixed(2) * this.nodeSize + 56;
      });
    },
    outcomeY() {
      return this.outputs.map(output => {
        return (output.value / this.outputSum).toFixed(2) * this.nodeSize + 56;
      });
    },
    inputSum() {
      return this.inputs.reduce((a, b) => a + b.value, 0);
    },
    outputSum() {
      return this.outputs.reduce((a, b) => a + b.value, 0);
    },
    title() {
      return this.clusterData.entity || this.clusterData.cluster
    },
    totalSum() {
      return ` ---- BTC (----%)`;
    },

    compInputs() {
      return this.inputs.map((input, index) => ({
        ...input,
        compValue: `---- BTC (---%)`,
        color: '#bbbbbb',
        height: (input.value / this.inputSum).toFixed(2) * this.nodeSize,
        offset: this.calcOffset(this.inputs, this.inputSum, index),
        y: this.calcY(this.incomeY, index)
      }));
    },
    compOutputs() {
      return this.outputs.map((output, index) => ({
        ...output,
        compValue: `---- BTC (---%)`,
        color: '#bbbbbb',
        height: (output.value / this.outputSum) * this.nodeSize,
        offset: this.calcOffset(this.outputs, this.outputSum, index),
        y: this.calcY(this.outcomeY, index)
      }));
    }
  },
  methods: {
    calcOffset(array, summ, index) {
      return array
          .slice(0, index)
          .reduce((a, b) => a + (b.value / summ) * this.nodeSize, 0);
    },
    calcY(array, i) {
      return array.slice(0, i).reduce((a, b) => a + b, 0);
    },
    createIncomeCurve(index, item) {
      // M 200, 300 C 100, 100, 500, 100, 400, 300
      // M x1, y1 C cx1, cy1, cx2, cy2, x2, y2
      // Where (x1, y1) and (x2, y2) are the end points of the path
      //and (cx1, cy1) and (cx2, cy2) are the control points.
      let x1 = this.nodeSize;
      let y1 = item.y + item.height / 2 + 50; // 50 - text offset
      let x2 = (this.width - this.nodeSize) / 2;
      let y2 = 80 + item.height / 2 + item.offset;
      let cx1 = x1 + (x2 - x1) / 2;
      let cy1 = y1;
      let cx2 = cx1;
      let cy2 = y2;
      return `M ${x1}, ${y1} C ${cx1}, ${cy1}, ${cx2}, ${cy2}, ${x2}, ${y2}`;
    },
    createOutcomeCurve(index, item) {
      let x2 = this.width - this.nodeSize;
      let y2 = item.y + item.height / 2 + 50;
      let x1 = (this.width + this.nodeSize) / 2;
      let y1 = 80 + item.height / 2 + item.offset;
      let cx1 = x1 + (x2 - x1) / 2;
      let cy1 = y1;
      let cx2 = cx1;
      let cy2 = y2;
      return `M ${x1}, ${y1} C ${cx1}, ${cy1}, ${cx2}, ${cy2}, ${x2}, ${y2}`;
    }
  }
};
</script>

<style>
.chart__loader {
  color: #bbb;
}


</style>

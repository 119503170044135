<template>
  <div class="relative">
    <div id="tooltip" />
    <svg
      class="chart"
      :height="height"
      :width="width"
    >
      <g
        v-if="loadingInputsData"
        class="rect-loader"
      >
        <g
          v-for="(input, index) in compInputsLoading"
          :key="`input-${index}`"
        >
          <text
            x="0"
            :y="input.y + 20"
          >{{ input.name }}</text>\
          <text
            fill="#a2a8c1"
            font-weight="500"
            x="0"
            :y="input.y + 40"
          >
            {{ input.compValue }}
          </text>
          <rect
            :fill="input.color"
            :height="input.height"
            :width="nodeSize"
            x="0"
            :y="input.y + 50"
          />
          <path
            :d="createIncomeCurve(index, input)"
            fill="none"
            :stroke="input.color"
            stroke-opacity="0.25"
            :stroke-width="input.height"
          />
        </g>
      </g>
      <g v-else>
        <g
          v-for="(input, index) in compInputs"
          :key="`input-${index}`"
        >
          <text
            x="0"
            :y="input.y + 20"
          >{{ input.name }}</text>
          <text
            v-tooltip.top="{ content: input.tooltip }"
            fill="#a2a8c1"
            font-weight="500"
            x="0"
            :y="input.y + 40"
          >
            {{ input.compValue }}
          </text>
          <rect
            :class="`input-${input.id}`"
            :fill="input.color"
            :height="input.height"
            :width="nodeSize"
            x="0"
            :y="input.y + 50"
          />
          <path
            :class="`path-${input.id}`"
            :d="createIncomeCurve(index, input)"
            fill="none"
            :stroke="input.color"
            stroke-opacity="0.25"
            :stroke-width="input.height"
          />
        </g>
      </g>
      <g
        v-if="loadingOutputsData"
        class="rect-loader"
      >
        <g
          v-for="(output, index) in compOutputsLoading"
          :key="index"
        >
          <text
            text-anchor="end"
            :x="width"
            :y="output.y + 20"
          >
            {{ output.name }}
          </text>
          <text
            fill="#a2a8c1"
            font-weight="500"
            text-anchor="end"
            :x="width"
            :y="output.y + 40"
          >
            {{ output.compValue }}
          </text>
          <rect
            :fill="output.color"
            :height="output.height"
            :width="nodeSize"
            :x="width - 150"
            :y="output.y + 50"
          />
          <path
            :d="createOutcomeCurve(index, output)"
            fill="none"
            :stroke="output.color"
            stroke-opacity="0.25"
            :stroke-width="output.height"
          />
        </g>
      </g>
      <g v-else>
        <g
          v-for="(output, index) in compOutputs"
          :key="index"
        >
          <text
            text-anchor="end"
            :x="width"
            :y="output.y + 20"
          >
            {{ output.name }}
          </text>
          <text
            v-tooltip.top="{ content: output.tooltip }"
            fill="#a2a8c1"
            font-weight="500"
            text-anchor="end"
            :x="width"
            :y="output.y + 40"
          >
            {{ output.compValue }}
          </text>
          <rect
            :class="`rect-${output.id}`"
            :fill="output.color"
            :height="output.height"
            :width="nodeSize"
            :x="width - 150"
            :y="output.y + 50"
          />
          <path
            :class="`path-${output.id}`"
            :d="createOutcomeCurve(index, output)"
            fill="none"
            :stroke="output.color"
            stroke-opacity="0.25"
            :stroke-width="output.height"
          />
        </g>
      </g>
      <template v-if="loadingData">
        <WalletLoader :x="(width - 36) / 2" />
        <text
          text-anchor="middle"
          :x="width / 2"
          :y="48"
        >-------</text>
        <text
          fill="#a2a8c1"
          font-weight="500"
          text-anchor="middle"
          :x="width / 2"
          :y="68"
        >
          --------
        </text>
        <rect
          fill="#f0f2f9"
          :height="nodeSize"
          :width="nodeSize"
          :x="(width - nodeSize) / 2"
          :y="80"
        />
      </template>
      <template v-else>
        <WalletRisk
          v-if="mainData.risk"
          :x="(width - 36) / 2"
        />
        <wallet
          v-else
          :x="(width - 36) / 2"
        />
        <text
          class="bold"
          text-anchor="middle"
          :x="width / 2"
          :y="48"
        >{{ title }}</text>
        <text
          v-if="!loadingData && !loadingInputsData && !loadingOutputsData"
          fill="#a2a8c1"
          font-weight="500"
          text-anchor="middle"
          :x="width / 2"
          :y="68"
        >
          {{ totalSum }}
        </text>
        <rect
          fill="#f0f2f9"
          :height="nodeSize"
          :width="nodeSize"
          :x="(width - nodeSize) / 2"
          :y="80"
        />
      </template>
    </svg>
  </div>
</template>

<script>
// Utils
import { formatBtcAmount } from "@/utils/format-btc-amount";
import { formatShare } from "@/utils/sourcesFormatter";
import { findColorByTypeScore } from "@/utils/cytoskape-ui-rules";

// Components
import Wallet from "@/assets/icons/wallet.svg?inline";
import WalletLoader from "@/assets/icons/wallet-loading.svg?inline";
import WalletRisk from "@/assets/icons/wallet-risk.svg?inline";
import FlowChartLoader from "@/pages/sankey-analytics/components/FlowChartLoader"

// Vuex
import { mapState } from "vuex";

export default {
  components: {
    Wallet,
    WalletLoader,
    WalletRisk,
    FlowChartLoader
  },
  props: {
    inputs: {
      type: Array,
      required: true
    },
    outputs: {
      type: Array,
      required: true
    },
    width: {
      type: Number,
      default: 600
    },
    loadingInputsData: {
      type: Boolean,
      default: false
    },
    loadingOutputsData: {
      type: Boolean,
      default: false
    },
    loadingData: {
      type: Boolean,
      default: false
    },
    mainData: {
      type: Object,
      default: () => ({})
    },
    nodeSize: {
      type: Number,
      default: 150
    }
  },
  data() {
    return {
      colors: [],
      inputsLoading: [
        { name: "--------", value: 1 },
        { name: "--------", value: 2.5 },
        { name: "--------", value: 5 },
        { name: "--------", value: 0.25 }
      ],
      outputsLoading: [
        { name: "--------", value: 2 },
        { name: "--------", value: 1 },
        { name: "--------", value: 0.25 },
        { name: "--------", value: 0.5 }
      ]
    };
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    height() {
      let inc = this.inputs.length * 56 + this.nodeSize;
      let out = this.outputs.length * 56 + this.nodeSize;
      return Math.max(inc, out);
    },
    incomeY() {
      return this.inputs.map(input => {
        return (input.value / this.inputSum).toFixed(2) * this.nodeSize + 56;
      });
    },
    outcomeY() {
      return this.outputs.map(output => {
        return ((output.value / this.outputSum) / this.transgrowthInputSumToOutputSum).toFixed(2) * this.nodeSize + 56;
      });
    },
    inputSum() {
      return this.inputs.reduce((a, b) => a + b.value, 0);
    },
    outputSum() {
      return this.outputs.reduce((a, b) => a + b.value, 0);
    },
    transgrowthInputSumToOutputSum() {
      return (this.inputSum / this.outputSum).toFixed(5) || 1
    },
    title() {
      return this.mainData.title || '--'
    },
    totalSum() {
      return `${this.formatBtcAmount(Math.max(
          this.inputs.reduce((a, b) => a + b.value, 0),
          this.outputs.reduce((a, b) => a + b.value, 0)
      ), false)} ${this.coinData.label} (100%)`;
    },

    compInputs() {
      return this.inputs.map((input, index) => ({
        ...input,
        id: `${input._id}-${input.address}-${index}`,
        tooltip: this.inputs.length === 1 ? '100.00%' : `${input.originalShare * 100}%`,
        compValue: `${this.formatBtcAmount(input.value, false)} ${this.coinData.label} (${this.inputs.length === 1 ? this.formatShare(1) : this.formatShare(input.share)})`,
        color: input.funds.default ? findColorByTypeScore(-1) : findColorByTypeScore(input.funds.score),
        height: (input.value / this.inputSum).toFixed(2) * this.nodeSize < 0.5 ? 0.5 : (input.value / this.inputSum).toFixed(2) * this.nodeSize,
        offset: this.calcOffset(this.inputs, this.inputSum, index),
        y: this.calcY(this.incomeY, index)
      }));
    },
    compOutputs() {
      return this.outputs.map((output, index) => ({
        ...output,
        id: `${output._id}-${output.address}-${index}`,
        tooltip: this.outputs.length === 1 ? '100.00%' : `${output.originalShare * 100}%`,
        compValue: `${this.formatBtcAmount(output.value, false)} ${this.coinData.label} (${this.outputs.length === 1 ? this.formatShare(1) : this.formatShare(output.share)})`,
        color: output.funds.default ? findColorByTypeScore(-1) : findColorByTypeScore(output.funds.score),
        height: ((output.value / this.outputSum) * this.nodeSize) / this.transgrowthInputSumToOutputSum < 0.5 ? 0.5 : ((output.value / this.outputSum) * this.nodeSize) / this.transgrowthInputSumToOutputSum,
        offset: this.calcOffset(this.outputs, this.outputSum, index) / this.transgrowthInputSumToOutputSum,
        y: this.calcY(this.outcomeY, index)
      }));
    },

    // Loading state
    heightLoading() {
      let inc = this.inputsLoading.length * 56 + this.nodeSize;
      let out = this.outputsLoading.length * 56 + this.nodeSize;
      return Math.max(inc, out);
    },
    incomeYLoading() {
      return this.inputsLoading.map(input => {
        return (input.value / this.inputSumLoading).toFixed(2) * this.nodeSize + 56;
      });
    },
    outcomeYLoading() {
      return this.outputsLoading.map(output => {
        return (output.value / this.outputSumLoading).toFixed(2) * this.nodeSize + 56;
      });
    },
    inputSumLoading() {
      return this.inputsLoading.reduce((a, b) => a + b.value, 0);
    },
    outputSumLoading() {
      return this.outputsLoading.reduce((a, b) => a + b.value, 0);
    },

    compInputsLoading() {
      return this.inputsLoading.map((input, index) => ({
        ...input,
        compValue: `---- ${this.coinData.label} (---%)`,
        color: '#bbbbbb',
        height: (input.value / this.inputSumLoading).toFixed(2) * this.nodeSize,
        offset: this.calcOffset(this.inputsLoading, this.inputSumLoading, index),
        y: this.calcY(this.incomeYLoading, index)
      }));
    },
    compOutputsLoading() {
      return this.outputsLoading.map((output, index) => ({
        ...output,
        compValue: `---- ${this.coinData.label} (---%)`,
        color: '#bbbbbb',
        height: (output.value / this.outputSumLoading) * this.nodeSize,
        offset: this.calcOffset(this.outputsLoading, this.outputSumLoading, index),
        y: this.calcY(this.outcomeYLoading, index)
      }));
    }
  },
  methods: {
    formatShare,
    formatBtcAmount,
    findColorByTypeScore,
    calcOffset(array, summ, index) {
      return array
          .slice(0, index)
          .reduce((a, b) => a + (b.value / summ) * this.nodeSize, 0);
    },
    calcY(array, i) {
      return array.slice(0, i).reduce((a, b) => a + b, 0);
    },
    showTooltip(evt, data) {
      if (!data.txsCount) return
      let tooltip = document.getElementById("tooltip");
      tooltip.innerHTML = `
         <div>Txs Count: ${data.txsCount}</div>`;
      tooltip.style.display = "block";
      tooltip.style.left = evt.layerX + 10 + 'px';
      tooltip.style.top = evt.layerY + 10 + 'px';
    },
    hideTooltip() {
      const tooltip = document.getElementById("tooltip");
      tooltip.style.display = "none";
    },
    handleHover(e, rect) {
      const connectedPath = document.getElementsByClassName(`path-${rect.id}`)[0]

      if (connectedPath) {
        connectedPath.style.strokeWidth = rect.height + 4

        e.target.setAttribute('height', rect.height + 4)
        e.target.setAttribute('y', e.target.y.baseVal.value - 2)
      }
    },
    handleLeave(e, rect) {
      this.hideTooltip()
      const connectedPath = document.getElementsByClassName(`path-${rect.id}`)[0]

      if (connectedPath) {
        connectedPath.style.strokeWidth = rect.height

        e.target.setAttribute('height', rect.height)
        e.target.setAttribute('y', e.target.y.baseVal.value + 2)
      }
    },
    createIncomeCurve(index, item) {
      // M 200, 300 C 100, 100, 500, 100, 400, 300
      // M x1, y1 C cx1, cy1, cx2, cy2, x2, y2
      // Where (x1, y1) and (x2, y2) are the end points of the path
      //and (cx1, cy1) and (cx2, cy2) are the control points.
      let x1 = this.nodeSize;
      let y1 = item.y + item.height / 2 + 50; // 50 - text offset
      let x2 = (this.width - this.nodeSize) / 2;
      let y2 = 80 + item.height / 2 + item.offset;
      let cx1 = x1 + (x2 - x1) / 2;
      let cy1 = y1;
      let cx2 = cx1;
      let cy2 = y2;
      return `M ${x1}, ${y1} C ${cx1}, ${cy1}, ${cx2}, ${cy2}, ${x2}, ${y2}`;
    },
    createOutcomeCurve(index, item) {
      let x2 = this.width - this.nodeSize;
      let y2 = item.y + item.height / 2 + 50;
      let x1 = (this.width + this.nodeSize) / 2;
      let y1 = 80 + item.height / 2 + item.offset;
      let cx1 = x1 + (x2 - x1) / 2;
      let cy1 = y1;
      let cx2 = cx1;
      let cy2 = y2;
      return `M ${x1}, ${y1} C ${cx1}, ${cy1}, ${cx2}, ${cy2}, ${x2}, ${y2}`;
    }
  }
};
</script>

<style>
#tooltip {
  display: none;
  position: absolute;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  padding: 5px;
  width: max-content;
}

#tooltip .hidden {
  display: none;
}

.chart {
  min-height: 400px;
}

.rect-loader rect{
  -webkit-animation: fadein 1s ease-in alternate infinite;
  -moz-animation: fadein 1s ease-in alternate infinite;
  animation: fadein 1s ease-in alternate infinite;
}

@keyframes fadein {
  from { opacity: 0.3; }
  to { opacity: 1; }
}
</style>

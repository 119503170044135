<template>
  <div>
    <div class="report-block__header">
      <div>
        Cluster information
      </div>
    </div>
    <div>
      <InfoBlock
        class="mb-4"
        label="owner"
        :loading="loading"
        :value="clusterData
          && clusterData.owner"
      />
      <div
        v-if="clusterData.assumedMeta && clusterData.assumedMeta.length > 0"
        class="mb-4"
      >
        <p class="sidebar__analytics-label">
          Tags
        </p>
        <GlTag
          v-for="(tag, index) in clusterData.assumedMeta"
          :key="index"
          class="mr-1 mb-1"
          :score="tag.score"
          :tag="capitalizeFirstLetter(tag.name)"
        />
      </div>
    </div>
    <div class="wrapper">
      <InfoBlock
        class="mb-4"
        label="cluster id"
        :loading="loading"
        :value="clusterData
          && clusterData.cluster"
      />
      <InfoBlock
        class="mb-4"
        label="total sent"
        :loading="loadingOutputs || loadingInputs"
        :value="clusterData
          && clusterData.totalAmountSent
          && toComaSeparate(String(formatBtcAmount(clusterData.totalAmountSent))) || '0'"
      />
      <InfoBlock
        class="mb-4"
        label="Total tx sent"
        :loading="loading"
        :value="clusterData
          && clusterData.totalTxSentCount"
      />
      <InfoBlock
        class="mb-4"
        label="First seen"
        :loading="loading"
        :value="clusterData
          && clusterData.firstSeen
          && formatDate(clusterData.firstSeen * 1000, 'dd.MM.yyyy HH:mm')"
      />
      <InfoBlock
        class="mb-4"
        label="Cluster size"
        :loading="loading"
        :value="clusterData
          && clusterData.addressCount"
      />
      <InfoBlock
        class="mb-4"
        label="Total received"
        :loading="loadingOutputs || loadingInputs"
        :value="clusterData
          && clusterData.totalAmountReceived
          && toComaSeparate(String(formatBtcAmount(clusterData.totalAmountReceived))) || '0'"
      />
      <InfoBlock
        class="mb-4"
        label="Total tx received"
        :loading="loading"
        :value="clusterData
          && clusterData.totalTxReceivedCount"
      />
      <InfoBlock
        class="mb-4"
        label="Last seen"
        :loading="loading"
        :value="clusterData
          && Number(clusterData.lastSeen)
          && formatDate(clusterData.lastSeen * 1000, 'dd.MM.yyyy HH:mm')"
      />
    </div>
  </div>
</template>

<script>
// Components
import InfoBlock from '@/components/gl-info-block'
import GlTag from "@/components/gl-tag";
// Utils
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from "@/utils/formatNumber";
import { formatBtcAmount } from "@/utils/format-btc-amount";
import { capitalizeFirstLetter } from "@/utils/text-formatter";
export default {
  components: {
    InfoBlock,
    GlTag,
  },
  props: {
    clusterData: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingInputs: {
      type: Boolean,
      default: false
    },
    loadingOutputs: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatDate,
    toComaSeparate,
    formatBtcAmount,
    capitalizeFirstLetter,
  }
}
</script>

<template>
  <GlPageWrap title="Sankey Analytics">
    <div class="sankey__filters-wrap">
      <div class="mb-3 flex space-between align-flex-end">
        <div class="flex">
          <gl-input
            v-model="searchValue"
            class="sankey__cluster-input mr-4"
            :height="40"
            label="Search by Address/Cluster ID"
            placeholder="Type the cluster or address"
          />
          <div class="flex align-center">
            <div class="mr-4">
              <div
                class="gl-input__label"
                style="margin-bottom: 14px"
              >
                filter by date & time
              </div>
              <date-picker
                ref="datePicker"
                v-model="transactionsListDates"
                class="sankey__calendar"
                confirm
                confirm-text="Apply"
                :disabled-date="disabledRange"
                format="YYYY-MM-DD"
                placeholder="Select datetime range"
                range
                :show-time-panel="false"
                @clear="clearDate"
                @close="handleRangeClose"
                @confirm="setDate(transactionsListDates)"
              >
                <template v-slot:footer>
                  <button
                    class="mx-btn mx-btn-text cancel-date-range mr-2"
                    @click="handleDateClose"
                  >
                    Cancel
                  </button>
                </template>
              </date-picker>
            </div>
            <div class="mr-4">
              <div
                class="gl-input__label"
                style="margin-bottom: 14px"
              >
                Group by type/owner
              </div>
              <vSelect
                v-model="groupBy"
                class="mr-2"
                :clearable="false"
                label="label"
                :options="groupList"
                @input="regrouping"
              />
            </div>
            <div class="mt-3">
              <gl-menu-item
                class="mt-4"
                icon="reset-all"
                :icon-height="24"
                :icon-width="24"
                :label="'Reset'"
                @click="resetAll"
              />
            </div>
          </div>
        </div>
        <gl-button
          dark
          :disabled="!searchValue"
          :loading="loadingFlowChart"
          style="height: 40px"
          title="search"
          @click="search"
        />
      </div>
      <ClusterInfoBlock
        v-if="clusterData.cluster"
        :cluster-data="clusterData"
        :loading="loadingClusterData"
        :loading-inputs="loadingInputs"
        :loading-outputs="loadingOutputs"
      />
      <AddressInfoBlock
        v-if="addressData.address"
        :address-data="addressData"
        :loading="loadingAddressData"
        :loading-inputs="loadingInputs"
        :loading-outputs="loadingOutputs"
      />
      <div class="sankey__chart-wrapper">
        <div
          v-if="!loadingFlowChart && formattedData(inputs).length === 0 && formattedData(outputs).length === 0"
          class="mb-3"
        >
          No Sankey Analytics data
        </div>
        <flow-chart
          v-else
          class="mt-3 flex justify-content-center"
          :inputs="formattedData(inputs)"
          :loading-data="loadingClusterData || loadingAddressData"
          :loading-inputs-data="loadingInputs || loadingOutputs"
          :loading-outputs-data="loadingOutputs || loadingInputs"
          :main-data="clusterData || addressData"
          :outputs="formattedData(outputs)"
          :width="chartWidth"
        />
      </div>
    </div>
  </GlPageWrap>
</template>

<script>
import vSelect from 'vue-select';
import GlInput from '@/components/gl-input';
import GlButton from '@/components/gl-button';
import GlMenuItem from '@/components/gl-menu-item';
import GlPageWrap from "@/components/layout/gl-page-wrap";
import FlowChart from "@/pages/sankey-analytics/components/FlowChart";
import ClusterInfoBlock from "@/pages/sankey-analytics/components/ClusterInfoBlock";
import AddressInfoBlock from "@/pages/sankey-analytics/components/AddressInfoBlock";
import moment from "moment"
import {validate} from "vee-validate";
// Utils
import { formatDate } from "@/utils/format-date";
import { formatter } from "@/utils/sourcesFormatter";
import {capitalizeFirstLetter, getValueByNestedKey} from "@/utils/text-formatter";
// Vuex
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TheSankey',
  components: {
    vSelect,
    GlInput,
    ClusterInfoBlock,
    AddressInfoBlock,
    GlButton,
    FlowChart,
    GlMenuItem,
    GlPageWrap,
  },
  data: () => ({
    cluster: null,
    address: null,
    searchValue: null,
    loadingAddressData: false,
    loadingInputs: false,
    loadingOutputs: false,
    loadingClusterData: false,
    leftDays: 30,
    pagesOptions: [10, 20, 50],
    inputs: [],
    outputs: [],
    inputsInitialSources: [],
    outputsInitialSources: [],
    chartWidth: 1100,
    clusterData: {},
    addressData: {},
    transactionsListDates: [new Date(String(moment('"2009-01-01"').format('YYYY-MM-DD'))), new Date(String(moment().format('YYYY-MM-DD')))],
    showTimePanel: false,
    showTimeRangePanel: false,
    from: null,
    to: null,
    groupBy: {
      key: 'type',
      label: 'Group by type',
      groupedFiled: 'funds.type',
    },
    groupList: [
      {
        key: 'type',
        label: 'Group by type',
        groupedFiled: 'funds.type',
      },
      {
        key: 'owner',
        label: 'Group by owner',
        groupedFiled: 'owner',
      }
    ]
  }),
  computed: {
    ...mapState('analytics', ['coinData']),
    loadingFlowChart() {
      return this.loadingInputs || this.loadingOutputs || this.loadingClusterData
    },
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.myEventHandler()
    const { cluster, address, from, to } = this.$route.query

    if (cluster) {
      this.cluster = cluster
      this.searchValue = cluster
    }

    if (address) {
      this.address = address
      this.searchValue = address
    }

    if (from && to) {
      this.transactionsListDates = [new Date(String(moment.unix(from).format('YYYY-MM-DD'))), new Date(String(moment.unix(to).format('YYYY-MM-DD')))]
    }

    this.search()
  },
  methods: {
    formatDate,
    getValueByNestedKey,
    capitalizeFirstLetter,
    formatter,
    ...mapActions({
      getAddressData: 'analytics/getAddressData',
      getClusterStat: 'analytics/getClusterStat',
      getSource: 'analytics/getSource',
      getSankeyData: 'analytics/getSankeyData',
    }),
    ...mapActions('tagging', ['getClusterInfo']),
    accAmountSources(sources) {
      return sources.map(el => el.amount).reduce((curr, acc) => acc + curr, 0)
    },
    formattedData(data) {
      return data.map((item) => ({
        ...item,
        name: this.groupBy.key === 'owner' ? this.capitalizeFirstLetter(item[this.groupBy.key]) : this.capitalizeFirstLetter(item.funds[this.groupBy.key]),
        searchTitle: this.searchValue,
        value: item.amount
      })).sort((a, b) =>
          b.share - a.share
      ) || []
    },
    disabledRange(date) {
      return date > moment().endOf('day');
    },
    myEventHandler() {
      const chart = document.getElementsByClassName('sankey__chart-wrapper')[0]
      this.chartWidth = chart.clientWidth - 50
    },
    handleDateClose() {
      this.transactionsListDates = [new Date(('"2009-01-01"')), new Date(moment().format('YYYY-MM-DD'))]
      const [from, to] = this.transactionsListDates
      this.from = from;
      this.to = to;
      this.$refs.datePicker.closePopup()
      this.search();
    },
    clearDate() {
      this.transactionsListDates = [new Date(('"2009-01-01"')), new Date(moment().format('YYYY-MM-DD'))]
      const [from, to] = this.transactionsListDates
      this.from = from;
      this.to = to;
      this.search();
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    toSecondsStart(date, formatDate = 'YYYY-MM-DD HH:mm') {
      return moment(date, formatDate).unix()
    },
    toSecondsEnd(date, formatDate = 'YYYY-MM-DD HH:mm') {
      return moment(date, formatDate).endOf('day').unix()
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    regrouping() {
      this.inputs = this.inputsInitialSources ? this.formatter(this.inputsInitialSources, this.groupBy.groupedFiled) : []
      this.outputs = this.outputsInitialSources ? this.formatter(this.outputsInitialSources, this.groupBy.groupedFiled) : []
    },
    loadSankeyData(type, search) {
      this.getSankeyData({ [type]: search, from: this.from, to: this.to, directionUp: false }).then(({ data, success }) => {
        if (success) {
          this.inputsInitialSources = data.inputs.sources
          this.inputs = data.inputs.sources ? this.formatter(data.inputs.sources, this.groupBy.groupedFiled) : []
          this.addressData.amountReceived = this.accAmountSources(this.inputs)
          this.clusterData.totalAmountReceived = this.accAmountSources(this.inputs)

          this.outputsInitialSources = data.outputs.sources
          this.outputs = data.outputs.sources ? this.formatter(data.outputs.sources, this.groupBy.groupedFiled) : []
          this.addressData.amountSent = this.accAmountSources(this.outputs)
          this.clusterData.totalAmountSent = this.accAmountSources(this.outputs)
        }
        else {
          this.inputs = []
          this.inputsInitialSources = []
          this.outputs = []
          this.outputsInitialSources = []
          this.$toasted.global.error({message: `${data.message}`})
        }
        this.loadingInputs = false
        this.loadingOutputs = false
      }).finally(() => {
        this.loadingInputs = false
        this.loadingOutputs = false
      })
    },
    search() {
      this.addressData = {}
      this.clusterData = {}
      if (!this.searchValue) return
      const [from, to] = this.transactionsListDates
      if (from && to) {
        this.from = this.toSecondsStart(from);
        this.to = this.toSecondsEnd(to);
        this.$refs.datePicker.closePopup()
      }
      validate(this.searchValue, `address:${this.coinData.key}`, { name: 'Search value' }).then(({ valid }) => {
        if (valid) {
          this.address = this.searchValue
          this.$router.replace({ name: 'sankey-analytics', query: { address: this.address, from: this.from, to: this.to } })
          this.loadingInputs = true
          this.loadingOutputs = true
          this.loadingAddressData = true
          this.loadSankeyData('address', this.address)
          this.getAddressData(this.searchValue).then(({ data }) => {
            this.addressData = {
              ...data,
              clusterOwner: data?.clusterData?.owner,
              amountReceived: this.accAmountSources(this.inputsInitialSources),
              amountSent: this.accAmountSources(this.outputsInitialSources),
              title: data.address,
              assumedMeta: this.formattingScoringList(data)
            }
          }).finally(() => {
            this.loadingAddressData = false
          })
        } else {
          this.cluster = this.searchValue
          this.$router.replace({ name: 'sankey-analytics', query: { cluster: this.cluster, from: this.from, to: this.to } })
          this.loadingInputs = true
          this.loadingOutputs = true
          this.loadingClusterData = true
          this.loadSankeyData('cluster', this.cluster)
          this.getClusterStat(this.cluster).then(({ data, success }) => {
            if (success) {
              this.clusterData = data

              this.getClusterInfo(this.cluster).then(({ data }) => {
                this.clusterData = {
                  ...this.clusterData,
                  ...data,
                  totalAmountReceived: this.accAmountSources(this.inputsInitialSources),
                  totalAmountSent: this.accAmountSources(this.outputsInitialSources),
                  title: data.cluster,
                  assumedMeta: this.formattingScoringList(data)
                }
              })
            }
          }).finally(() =>  this.loadingClusterData = false)
        }
      })
    },
    formattingScoringList(data) {
      let SCORING_LIST = []
      if (data.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.tags]
      }
      if (data.type) {
        SCORING_LIST = [...SCORING_LIST, data.type]
      }
      SCORING_LIST = SCORING_LIST.filter((v,i,a)=>a.findIndex(t=>(t.name===v.name))===i)
      SCORING_LIST.sort((a, b) => ((a.score < b.score)) ? 1 : -1)
      return SCORING_LIST
    },
    setDate(date) {
      const [from, to] = date
      if (from && to) {
        this.from = this.toSecondsStart(from);
        this.to = this.toSecondsEnd(to);
        this.$refs.datePicker.closePopup()
        this.search();
      } else this.clearDate()
    },
    resetAll() {
      this.clearDate()
      this.cluster = null
    },
  }
};
</script>

<style>
.sankey__calendar .mx-input {
  height: 40px !important;
}
.sankey__calendar {
  max-width: 400px;
  min-width: 300px;
}

.sankey__calendar .mx-datepicker-footer {
  display: flex !important;
  align-items: baseline;
  justify-content: flex-end;
}

.sankey__filters-wrap {
  padding: 24px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.sankey__cluster-input {
  width: 350px;
}

.sankey__chart-wrapper {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (min-width: 1450px) {
  .wrapper {
    grid-template-columns: repeat(4, 2fr);
  }
}
.report-block__header {
  align-items: center;
  display: flex;
  font-size: 24px;
  border-bottom: 2px solid var(--space-cadet);
  margin-bottom: 22px;
  padding-bottom: 12px;
  font-weight: 600;
}
</style>
